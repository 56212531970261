var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heads'),_c('div',{staticClass:"cen",style:({
      backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
    })},[_c('div',{staticClass:"cen_box"},[_c('div',{staticClass:"cen-com"},_vm._l((_vm.tradition),function(item,index){return _c('div',{key:index},[_c('router-link',{staticClass:"items",attrs:{"to":{
              path: '/traditionDetail',
              query: {
                id: item.id,
              },
            }}},[_vm._v(_vm._s(item.name))])],1)}),0)])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }